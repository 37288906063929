<template>
  <div>
    <v-row>
      <v-col cols="12" :md="12" v-show="!showExpand" class="pr-5 pt-0 pb-0">
        <div class="iframe-container" id="divIframe">
          <!-- iFrame -->
          <iframe :src="urlTablero" ref="myIframe" id="centralIframe"></iframe>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 1 : 11"
        v-show="!showExpand"
        v-if="this.$route.params.nameTable == undefined"
      >
        <v-tooltip bottom v-if="canConfig">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              icon
              v-bind="attrs"
              @click="configIframe()"
              class="to-right"
            >
              <v-icon size="30px">{{ settingsIcon }}</v-icon>
            </v-btn>
          </template>
          <span
            >Acceder a la configuración del tablero: {{ titleTablero }}</span
          >
        </v-tooltip>
        <v-tooltip bottom v-if="canReload">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              icon
              v-bind="attrs"
              @click="reloadIframe()"
              class="to-right"
            >
              <v-icon size="30px">{{ reloadIcon }}</v-icon>
            </v-btn>
          </template>
          <span>Recargar tablero: {{ titleTablero }}</span>
        </v-tooltip>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="10"
        class="text right pt-0 px-0 mr-0"
        :md="!showHelp ? 1 : showExpand ? 12 : 11"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="
            this.$route.params.nameTable != undefined
              ? this.optionCodeTablero
              : this.optionCode
          "
          :pantallaTitle="titleTablero"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="45%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditAnalyticsContainer
        :tituloDeAnalytics="titleTablero"
        @closeAndReload="closeAndReload"
      ></EditAnalyticsContainer>
    </v-dialog>
    <v-dialog
      v-if="openModalReload"
      v-model="openModalReload"
      max-width="50%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <ReloadTableroAnalytics
        :tituloDeAnalytics="titleTablero"
        @closeAndReload="closeAndReload"
      ></ReloadTableroAnalytics>
    </v-dialog>
  </div>
</template>

<script>
import Ayuda from "@/components/shared/Ayuda.vue";
import enums from "@/utils/enums/index.js";
import store from "@/store";
import { mapActions } from "vuex";
import EditAnalyticsContainer from "@/components/modules/analytics/EditAnalyticsContainer.vue";
import ReloadTableroAnalytics from "@/components/modules/analytics/ReloadTableroAnalytics.vue";

export default {
  name: "AnalyticsContainer",
  props: ["title", "optionCode"],
  components: {
    Ayuda,
    EditAnalyticsContainer,
    ReloadTableroAnalytics
  },
  async created() {
    this.setPermisos();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    if (this.$route.params.nameTable != undefined) {
      await this.setAnalyticsOtrosTableros();
      await this.configStoreDispatch();
    } else this.setAnalyticsGral();
  },
  data() {
    return {
      showIcon: true,
      settingsIcon: enums.icons.SETTINGS,
      reloadIcon: enums.icons.RELOAD,
      optionCodeOtrosTableros: enums.webSiteOptions.ANALYTICS_OTROS_TABLEROS,
      otrosTablerosItems: null,
      titleTablero: null,
      optionCodeTablero: null,
      urlTablero: null,
      showExpand: false,
      showHelp: false,
      openModalEdit: false,
      openModalReload: false,
      allowedActions: null,
      canConfig: false,
      canReload: false
    };
  },
  methods: {
    ...mapActions({
      getOtrosTableros: "analytics/getOtrosTableros",
      getConfiguracionTableros: "analytics/getConfiguracionTableros",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .CONFIGURAR_ANALYTICS_AFILIACIONES:
            this.canConfig = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONFIGURAR_ANALYTICS_PRESTACIONES:
            this.canConfig = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONFIGURAR_ANALYTICS_INGRESOS:
            this.canConfig = true;
            break;
          case enums.modules.adminSistema.permissions
            .RECARGAR_ANALYTICS_AFILIACIONES:
            this.canReload = true;
            break;
          case enums.modules.adminSistema.permissions
            .RECARGAR_ANALYTICS_PRESTACIONES:
            this.canReload = true;
            break;
          case enums.modules.adminSistema.permissions
            .RECARGAR_ANALYTICS_INGRESOS:
            this.canReload = true;
            break;
          default:
            break;
        }
      });
    },
    async configStoreDispatch() {
      try {
        await store.dispatch("user/changeActiveMenu", "Analytics");
        await store.dispatch(
          "user/updateFrequencyByOptionCode",
          this.optionCodeTablero
        );
      } catch (error) {
        this.$router.push({ name: "OtrosTablerosAnalytics" });
      }
    },
    async setAnalyticsOtrosTableros() {
      this.otrosTablerosItems = await this.getOtrosTableros(
        this.optionCodeOtrosTableros
      );
      const findObject = this.otrosTablerosItems.find(
        obj => obj.optionTitle == this.$route.params.nameTable
      );
      this.titleTablero = findObject.optionTitle;
      this.optionCodeTablero = findObject.optionCode;
      this.urlTablero = findObject.linkTableroDesencriptado;
    },
    async setAnalyticsGral() {
      const response = await this.getConfiguracionTableros();
      const findObj = response.find(x => x.optionTitle == this.title);
      if (findObj != null) {
        this.titleTablero = this.title;
        this.optionCodeTablero = this.optionCode;
        this.urlTablero = findObj.linkDesencriptado;
      } else {
        this.setAlert({
          type: "warning",
          message: "La opción seleccionada no tiene configurado el link del módulo."
        });
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    reloadIframe() {
      this.openModalReload = true;
    },
    configIframe() {
      this.openModalEdit = true;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.openModalReload = false;
    }
  }
};
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 50.25%;
  position: relative;
}
.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
