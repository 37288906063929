var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-5 pt-0 pb-0",attrs:{"cols":"12","md":12}},[_c('div',{staticClass:"iframe-container",attrs:{"id":"divIframe"}},[_c('iframe',{ref:"myIframe",attrs:{"src":_vm.urlTablero,"id":"centralIframe"}})])])],1),_c('v-row',[(this.$route.params.nameTable == undefined)?_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],attrs:{"cols":"12","md":_vm.showHelp ? 1 : 11}},[(_vm.canConfig)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"to-right",attrs:{"icon":""},on:{"click":function($event){return _vm.configIframe()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30px"}},[_vm._v(_vm._s(_vm.settingsIcon))])],1)]}}],null,false,2798547618)},[_c('span',[_vm._v("Acceder a la configuración del tablero: "+_vm._s(_vm.titleTablero))])]):_vm._e(),(_vm.canReload)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"to-right",attrs:{"icon":""},on:{"click":function($event){return _vm.reloadIframe()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30px"}},[_vm._v(_vm._s(_vm.reloadIcon))])],1)]}}],null,false,2326602957)},[_c('span',[_vm._v("Recargar tablero: "+_vm._s(_vm.titleTablero))])]):_vm._e()],1):_vm._e(),(_vm.showIcon)?_c('v-col',{staticClass:"text right pt-0 px-0 mr-0",attrs:{"cols":"10","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 11}},[_c('Ayuda',{attrs:{"optionCode":this.$route.params.nameTable != undefined
            ? this.optionCodeTablero
            : this.optionCode,"pantallaTitle":_vm.titleTablero},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1),(_vm.openModalEdit)?_c('v-dialog',{attrs:{"max-width":"45%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openModalEdit),callback:function ($$v) {_vm.openModalEdit=$$v},expression:"openModalEdit"}},[_c('EditAnalyticsContainer',{attrs:{"tituloDeAnalytics":_vm.titleTablero},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e(),(_vm.openModalReload)?_c('v-dialog',{attrs:{"max-width":"50%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openModalReload),callback:function ($$v) {_vm.openModalReload=$$v},expression:"openModalReload"}},[_c('ReloadTableroAnalytics',{attrs:{"tituloDeAnalytics":_vm.titleTablero},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }