<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-data-table
        :headers="headersReload"
        :items="reloadItems"
        class="elevation-1 mt-0"
        item-key=""
        :loading="isLoading"
        dense
      >
        <template v-slot:[`item.status`]="{ item }">
          <span :style="statusStyle(item)">
            {{ item.status }}
          </span>
        </template>
      </v-data-table>
      <v-card-actions>
        <p class="pleo mt-5 mr-9" v-if="reloadItems.length != 0">
          Última actualización:
          <span :style="statusStyle(reloadItems[0])"
            >{{ reloadItems[0].status }}.
          </span>
          {{
            reloadItems[0].status === "PROCESANDO" ||
            reloadItems[0].status === "EN COLA"
              ? ""
              : "¿Desea recargar el tablero?"
          }}
        </p>
        <v-spacer></v-spacer>
        <v-btn class="mt-2" @click="closeModal" outlined>Cerrar</v-btn>
        <v-btn
          :loading="loadingSaveBtn"
          color="primary"
          class="mt-2"
          :disabled="
            reloadItems.length != 0
              ? reloadItems[0].status === 'PROCESANDO' ||
                reloadItems[0].status === 'EN COLA'
              : false
          "
          @click="reloadTablero"
        >
          Recargar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ReloadTableroAnalytics",
  props: {
    tituloDeAnalytics: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      formEditTitle: "",
      isLoading: false,
      loadingSaveBtn: false,
      isIngresos: false,
      isPrestaciones: false,
      isAfiliaciones: false,
      resRunRecarga: null,
      reloadItems: [],
      headersReload: [
        {
          text: "Fecha inicio",
          align: "start",
          value: "fechaInicio",
          sortable: false
        },
        {
          text: "Fecha fin",
          align: "start",
          value: "fechaFin",
          sortable: false
        },
        {
          text: "Usuario",
          align: "start",
          value: "usuario",
          sortable: false
        },
        {
          text: "Tipo de recarga",
          align: "start",
          value: "tipoEjecucion",
          sortable: false
        },
        {
          text: "Estado",
          align: "start",
          value: "status",
          sortable: false
        }
      ]
    };
  },
  created() {
    this.formEditTitle = `Recargas anteriores de: ${this.tituloDeAnalytics}`;
    this.setBanderas();
    this.loadDataEjecucion();
  },
  methods: {
    ...mapActions({
      runRecargaManual: "analytics/runRecargaManual",
      getLogEjecucionByModulo: "analytics/getLogEjecucionByModulo",
      setAlert: "user/setAlert"
    }),
    statusStyle(item) {
      if (item.status === "OK") return "color:green";
      else if (item.status === "EN COLA") return "color:orange";
      else if (item.status === "PROCESANDO") return "color:blue";
      else return "color:red";
    },
    setBanderas() {
      switch (this.tituloDeAnalytics) {
        case "Afiliaciones":
          this.isAfiliaciones = true;
          break;
        case "Prestaciones":
          this.isPrestaciones = true;
          break;
        case "Ingresos":
          this.isIngresos = true;
          break;
        default:
          break;
      }
    },
    async loadDataEjecucion() {
      if (this.isAfiliaciones) {
        const response = await this.getLogEjecucionByModulo(1);
        if (response.length != 0) this.reloadItems = response;
      } else if (this.isPrestaciones) {
        const response = await this.getLogEjecucionByModulo(2);
        if (response.length != 0) this.reloadItems = response;
      } else if (this.isIngresos) {
        const response = await this.getLogEjecucionByModulo(3);
        if (response.length != 0) this.reloadItems = response;
      }
    },
    reloadTablero() {
      this.loadingSaveBtn = true;
      const dataAfiliaciones = [
        {
          moduloId: 1,
          tipoEjecucion: "Manual"
        }
      ];
      const dataPrestaciones = [
        {
          moduloId: 2,
          tipoEjecucion: "Manual"
        }
      ];
      const dataIngresos = [
        {
          moduloId: 3,
          tipoEjecucion: "Manual"
        }
      ];
      try {
        let promises = [];
        if (this.isAfiliaciones) {
          promises.push(this.runRecargaManual(dataAfiliaciones));
        } else if (this.isPrestaciones) {
          promises.push(this.runRecargaManual(dataPrestaciones));
        } else if (this.isIngresos) {
          promises.push(this.runRecargaManual(dataIngresos));
        }
        const result = Promise.all(promises);
        setTimeout(() => {
          this.setAlert({
            type: "info",
            message: "Ejecución manual en proceso."
          });
          this.closeModal();
          this.loadingSaveBtn = false;
        }, 1000);
      } catch (error) {
        this.loadingSaveBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
.pleo {
  text-align: center;
}
</style>
